/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
/* Primary */
._2U_i1YFIxuioiLerBCVUnJ {
  border-radius: 0 8px 8px 8px;
  box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.08);
  text-align: center;
}
@media (max-width: 668px) {
  ._2U_i1YFIxuioiLerBCVUnJ input {
    position: absolute;
    top: 0;
    transition: all 0.6s ease-in;
  }
}
.oX503p-m_mrxHHpJvNlVQ {
  flex: 1 !important;
  z-index: 2002;
}
._2sQYXQNVx93f7nX1uRrJYm {
  visibility: hidden;
  position: fixed;
  top: 100%;
  bottom: 0;
  left: 0;
  transition: all 0.6s ease-in;
  z-index: 2002;
}
@media (max-width: 668px) {
  ._2sQYXQNVx93f7nX1uRrJYm.ToNGgRTWI51My0S-5R8zq {
    visibility: visible;
    top: 0;
    height: 100%;
  }
}
._3JaYhbXO4aQp1zzpfLuwbV {
  visibility: hidden;
  opacity: 0;
  z-index: 2002;
  position: absolute;
  top: 16px;
  right: 24px;
  transition: opacity 0.5s 0.3s ease-in-out;
}
@media (max-width: 668px) {
  ._3JaYhbXO4aQp1zzpfLuwbV.ToNGgRTWI51My0S-5R8zq {
    visibility: visible;
    opacity: 1;
  }
}
@media (max-width: 668px) {
  .XLNYIuF7kCNRzV5mbW58_ {
    transition: all 0.5s ease-in-out;
    width: 100%;
  }
}
@media (max-width: 668px) {
  .XLNYIuF7kCNRzV5mbW58_.ToNGgRTWI51My0S-5R8zq {
    transform: translate(0%, 120px);
  }
}
@media (max-width: 668px) {
  .XLNYIuF7kCNRzV5mbW58_.ToNGgRTWI51My0S-5R8zq._2hHDo4rJBvqWRGw2DjsBUP {
    transform: translate(0%, 160px);
  }
}
@media (max-width: 668px) {
  .oX503p-m_mrxHHpJvNlVQ {
    position: relative;
    transition: all 0.6s ease-in-out;
    top: auto;
  }
}
._26l1BTVuPcfINHXWgL4BnL {
  display: none;
}
@media (max-width: 668px) {
  ._26l1BTVuPcfINHXWgL4BnL {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transition: none !important;
  }
}
@media (max-width: 668px) {
  .oX503p-m_mrxHHpJvNlVQ.ToNGgRTWI51My0S-5R8zq input {
    width: calc(100vw - 48px) !important;
    top: -244px;
  }
}
@media (max-width: 668px) {
  .oX503p-m_mrxHHpJvNlVQ.ToNGgRTWI51My0S-5R8zq._2hHDo4rJBvqWRGw2DjsBUP input {
    width: calc(100vw - 48px) !important;
    top: -192px;
    z-index: 2002;
  }
}
@media (max-width: 668px) {
  ._1_ydTYSQaYDwfKdqKKItHb {
    position: fixed;
    height: 100%;
    overflow: hidden;
  }
}
